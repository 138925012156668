import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    useLayoutEffect,
    useMemo,
} from 'react';
import {
    genericMessageHandler,
    formatPrice,
    numberFormat,
    Icon,
} from 'shared/helpers';
import {isMobile} from 'react-device-detect';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {ProductDetail, ProductQuantity} from 'components';
import {useNavigate} from 'react-router-dom';
import {cloneDeep, debounce, isEqual, isEmpty} from 'lodash';
import {
    invalidateProductAndCost,
    useDeleteCabinetMutation,
    useGetRoomProductsQuery,
    useUpdateOrderMutation,
    useUpdateProductQuantityMutation,
} from 'components/customer/Product/store/productApi';
import {useAppDispatch} from 'store/customer';

const getNoRecordMessage = (
    userProfile,
    job,
    showDialog,
    addProductUrl,
    doorsAndPanelsUrl,
    addBenchtopUrl,
    navigate
) => {
    if (job && job.variationsConfirmed == 1) {
        return <>No Products</>;
    }

    const fullTextDisplay = [];
    fullTextDisplay.push(
        userProfile.isAddProductAvailable ? (
            <span
                key={0}
                onClick={() => navigate(addProductUrl)}
                style={{cursor: 'pointer'}}>
                Add Product{' '}
            </span>
        ) : null
    );
    fullTextDisplay.push(
        userProfile.isQFPAvailable ? (
            <span
                key={1}
                onClick={() =>
                    isMobile
                        ? showDialog({
                              title: userProfile.qFPMenuText,
                              message:
                                  'This feature is only available in desktop version.',
                              hideYesButton: true,
                              hideNoButton: true,
                              keyboard: false,
                              buttons: [
                                  {
                                      name: 'Ok',
                                      show: true,
                                  },
                              ],
                          })
                        : navigate(doorsAndPanelsUrl)
                }
                style={{cursor: 'pointer'}}>
                , {userProfile.qFPMenuText}{' '}
            </span>
        ) : null
    );
    fullTextDisplay.push(
        userProfile.isBTDAvailable ? (
            <span
                key={2}
                onClick={() => navigate(addBenchtopUrl)}
                style={{cursor: 'pointer'}}>
                , Add BenchTop{' '}
            </span>
        ) : null
    );

    return (
        <>
            No Products.{' '}
            <span style={{color: 'red'}}>
                <strong>Click {fullTextDisplay.filter(Boolean)}</strong> to add
                one.
            </span>
        </>
    );
};

const getTableConfig = (
    dragDropType,
    userProfile,
    actionButtonsEnabled,
    job,
    room,
    isJobProcessing,
    showDialog,
    navigate,
    roomIndex
) => {
    const addProductUrl = `/v2/job/${job.displayId}/room/${room.id}/product`;
    const doorsAndPanelsUrl = `/v2/job/${job.displayId}/room/${room.id}/quick-flat-product`;
    const addBenchtopUrl = userProfile.isBTDAvailable
        ? `/v2/job/${job.displayId}/room/${room.id}/benchtop-designer`
        : null;

    const tableConfig = {
        fields: [
            {
                fieldName: 'room_cab_number',
                title: '#',
                width: 35,
                minWidth: 38,
                rowAction: false,
                format: (value) => {
                    if (!isJobProcessing) {
                        return (
                            <strong>
                                {roomIndex + 1}-{value}
                            </strong>
                        );
                    } else {
                        return (
                            <>
                                {roomIndex + 1}-{value}
                            </>
                        );
                    }
                },
            },
            {
                fieldName: 'typeName',
                title: <div>Product</div>,
                format: (field, row) => {
                    return <ProductDetail product={row} />;
                },
            },
            {
                fieldName: 'dimensionString',
                mobile: false,
                title: <div>Dimensions (mm)</div>,
                width: 150,
                format: (value, row) => `${row.dimensions}`,
            },
            userProfile.show_pricing && {
                fieldName: 'cost',
                mobile: false,
                title: (
                    <div>
                        Unit Price (ex.{' '}
                        {job.countryTaxRateName ? job.countryTaxRateName : ''})
                    </div>
                ),
                width: 150,
                minWidth: 65,
                format: (value, row) => (
                    <div className="total">
                        {userProfile.currencyType}
                        {numberFormat((value / row.quantity).toFixed(2))}
                    </div>
                ),
            },
            {
                fieldName: 'quantity',
                mobile: false,
                title: <div>Quantity</div>,
                width: 150,
                rowAction: false,
                format: function PriceField(field, row) {
                    if (!actionButtonsEnabled || !isJobProcessing) {
                        return field;
                    }
                    return (
                        <ProductQuantity value={field} productDetails={row} />
                    );
                },
            },
            userProfile.show_pricing && {
                fieldName: 'cost',
                mobile: false,
                title: <div>TOTAL</div>,
                width: 150,
                minWidth: 57,
                format: formatPrice,
            },
        ].filter((column) => column),
        hasCursor: true,
        noRecordsText: getNoRecordMessage(
            userProfile,
            job,
            showDialog,
            addProductUrl,
            doorsAndPanelsUrl,
            addBenchtopUrl,
            navigate
        ),
        cellSpacing: 0,
    };

    if (isJobProcessing) {
        tableConfig.reorder = {
            allowReorder: true,
            dragHandle: 0,
            type: dragDropType,
        };

        tableConfig.options = {
            title: <div>Actions</div>,
            width: isMobile ? 50 : 150,
            buttons: [],
        };
    }

    return tableConfig;
};

const getButtonsConfig = (editCabinet, removeProduct) => {
    return [
        {
            iconName: 'Options-Edit.svg',
            title: 'Edit Product',
            rowAction: true,
            action: (row, event) =>
                editCabinet(row, false, row.in_qfp_summary == 1, event),
        },
        {
            iconName: 'Options-Copy.svg',
            title: 'Copy Product',
            action: (row, event) => editCabinet(row, true, false, event),
            isHidden: (row) => row.in_qfp_summary == 1,
        },
        {
            iconName: 'Options-Delete.svg',
            title: 'Delete Product',
            action: removeProduct,
        },
    ];
};

const cabinetMapper =
    (roomIndex, limit = 0) =>
    (cabinet, index) => {
        return {
            ...cabinet,
            ...{
                roomCabNumber: index + limit + 1,
            },
        };
    };

const orderFieldFormatter = (
    tableConfig,
    reorderItems,
    type,
    totalItems,
    roomCabNumber
) => {
    tableConfig.fields = tableConfig.fields.map((field) => {
        if (field.fieldName == 'room_cab_number') {
            field.format = (value, row, fieldName, index) => {
                return (
                    <>
                        <Icon
                            iconName="Button-Up-Table.svg"
                            style={{
                                cursor: 'pointer',
                                opacity: index == 0 ? 0.5 : 1,
                            }}
                            disabled={index == 0}
                            onClick={() => reorderItems(index, index - 1, type)}
                        />
                        <strong>
                            {roomCabNumber + 1}-{value}
                        </strong>
                        <Icon
                            iconName="Button-Down-Table.svg"
                            style={{
                                cursor: 'pointer',
                                opacity: index == totalItems - 1 ? 0.5 : 1,
                            }}
                            disabled={index == totalItems - 1}
                            onClick={() => reorderItems(index, index + 1, type)}
                        />
                    </>
                );
            };
        }

        return field;
    });

    return tableConfig;
};

export const useJobDashboard = () => {
    const dispatch = useAppDispatch();
    const {
        actionButtonsEnabled,
        job,
        room,
        showDialog,
        isJobProcessing,
        roomIndex,
    } = useJobContext();
    const {notify} = useNotificationContext();
    const {userProfile, setIsMobileMenuVisible} = useAppContext();
    const navigate = useNavigate();

    const [qfps] = useState([]);
    const [products, setProducts] = useState([]);
    const [productTableConfig, setProdcutTableConfig] = useState();
    const [deleteProduct] = useDeleteCabinetMutation();
    const [updateOrder] = useUpdateOrderMutation();

    const {data: productData} = useGetRoomProductsQuery(
        {roomId: parseInt(room.id)},
        {
            skip: typeof room.id === 'undefined',
        }
    );

    const reorder = useRef(
        debounce(
            (updatedProducts, jobId, roomId) => {
                const newOrder = {};
                updatedProducts.forEach((product, index) => {
                    newOrder[product.id] = index + 1;
                });

                setProducts(updatedProducts);

                updateOrder({jobId, roomId, order: newOrder});
            },
            500,
            {maxWait: 1000}
        )
    ).current;

    const removeProduct = (product, event) => {
        event.stopPropagation();
        const button = event.target.parentElement;
        showDialog({
            title: 'Delete item',
            message: 'Are you sure you want to delete this item from the job?',
            yes: async () => {
                try {
                    button.disabled = true;
                    await deleteProduct({cabinetId: product.id});
                    dispatch(invalidateProductAndCost());

                    button.disabled = false;
                    genericMessageHandler(
                        notify,
                        {message: 'Item removed.'},
                        'success'
                    );
                } catch (e) {
                    button.disabled = false;
                    genericMessageHandler(notify, e);
                }
            },
        });
    };

    const editCabinet = (row, copy = false, isQFP = false, event) => {
        event.stopPropagation();
        let url = `/v2/job/${job.displayId}/room/${room.id}/product?product=${row.type}&cabinetId=${row.id}`;

        if (copy) {
            url += '&copy';
        }

        if (isQFP) {
            url = `/v2/job/${job.displayId}/room/${room.id}/quick-flat-product?edit=${row.id}`;
        }

        navigate(url);
    };

    const hasRoom = useMemo(() => {
        return job?.rooms?.length >= 1;
    }, [job]);

    const reorderItems = useCallback(
        (products) => {
            if (job && job.id && room && room.id) {
                setProducts(products);
                reorder(products, job.id, room.id);
            }
        },
        [job, room]
    );

    const reorderItemsManual = useCallback(
        (orderIndex, hoverIndex, type) => {
            if (job && job.id && room && room.id) {
                let updatedProducts = cloneDeep(products);

                updatedProducts.splice(
                    hoverIndex,
                    0,
                    updatedProducts.splice(orderIndex, 1)[0]
                );

                updatedProducts = updatedProducts.map(
                    cabinetMapper(roomIndex, qfps.length)
                );

                setProducts(updatedProducts);
                reorder(updatedProducts, job.id, room.id);
            }
        },
        [qfps, products, job, room, roomIndex]
    );

    useEffect(() => {
        if (productData) {
            if (!isEqual(products, productData)) {
                setProducts(productData);
            }
        } else if (isEmpty(room)) {
            // clear products associated when room is removed
            setProducts([]);
        }
    }, [productData, room]);

    useEffect(() => {
        const addProductUrl = `/v2/job/${job.displayId}/room/${room.id}/product`;
        const doorsAndPanelsUrl = `/v2/job/${job.displayId}/room/${room.id}/quick-flat-product`;
        const addBenchtopUrl = userProfile?.isBTDAvailable
            ? `/v2/job/${job.displayId}/room/${room.id}/benchtop-designer`
            : null;
        const addRoomUrl = `/v2/job/${job.displayId}/room`;

        if (Object.keys(userProfile).length > 0) {
            let tableConfig;
            if (typeof productTableConfig === 'undefined' || !isJobProcessing) {
                tableConfig = getTableConfig(
                    'Product-table-row',
                    userProfile,
                    actionButtonsEnabled,
                    job,
                    room,
                    isJobProcessing,
                    showDialog,
                    navigate,
                    roomIndex
                );
            } else {
                tableConfig = cloneDeep(productTableConfig);

                if (
                    isJobProcessing &&
                    typeof tableConfig.reorder === 'undefined'
                ) {
                    tableConfig.reorder = {
                        allowReorder: true,
                        dragHandle: 0,
                        type: 'Product-table-row',
                    };
                }
            }

            if (job?.status == 0 && job?.accepted == 0) {
                if (!tableConfig.options) {
                    tableConfig.options = {
                        title: <div>Actions</div>,
                        width: isMobile ? 50 : 150,
                        buttons: getButtonsConfig(editCabinet, removeProduct),
                        isHidden: false,
                    };
                }
            }

            if (isJobProcessing) {
                if (tableConfig.options) {
                    tableConfig.options.isHidden = !actionButtonsEnabled;
                    tableConfig.options.buttons = getButtonsConfig(
                        editCabinet,
                        removeProduct
                    );
                }
                if (actionButtonsEnabled) {
                    if (tableConfig.reorder !== undefined) {
                        tableConfig.reorder.order = reorderItems;
                    }
                    tableConfig = orderFieldFormatter(
                        tableConfig,
                        reorderItemsManual,
                        'Product-table-row',
                        products.length,
                        roomIndex
                    );
                }
            }

            if (products.length) {
                tableConfig.data = products;
            } else {
                tableConfig.data = [];
            }

            if (Object.keys(job).length == 0) {
                tableConfig.noRecordsText = '';
            } else if (!hasRoom && job?.variationsConfirmed === 0) {
                tableConfig.noRecordsText = (
                    <>
                        No Room has been added to the current job. Click{' '}
                        <span
                            style={{color: 'red', cursor: 'pointer'}}
                            onClick={() => navigate(addRoomUrl)}>
                            <strong>Add Room</strong> to set preferred cabinet
                            defaults.
                        </span>
                    </>
                );
            } else {
                tableConfig.noRecordsText = getNoRecordMessage(
                    userProfile,
                    job,
                    showDialog,
                    addProductUrl,
                    doorsAndPanelsUrl,
                    addBenchtopUrl,
                    navigate
                );
            }

            setProdcutTableConfig(tableConfig);
        }
    }, [
        hasRoom,
        products,
        userProfile,
        actionButtonsEnabled,
        isJobProcessing,
        job,
        room,
        roomIndex,
    ]);

    useLayoutEffect(() => {
        setIsMobileMenuVisible(true);
    }, []);

    return {
        productTableConfig,
    };
};

export const useProductQuantity = (value, productDetails) => {
    const dispatch = useAppDispatch();
    const [quantity, setQuantity] = useState(value);
    const [saving, setSaving] = useState(false);
    const {notify} = useNotificationContext();
    const {setIsPageDirty} = useJobContext();
    const [updateProductQuantity] = useUpdateProductQuantityMutation();

    const updateQuantity = (event) => {
        if (event.target.value == '') {
            setQuantity('');
        } else {
            let value = parseInt(event.target.value);

            if (value <= 0) value = 1;

            setQuantity(value);
        }
    };

    const decreaseQuantity = () => quantity >= 2 && setQuantity(quantity - 1);

    const increaseQuantity = () => setQuantity(quantity + 1);

    const updateProduct = async () => {
        if (!saving) {
            setSaving(true);
            setIsPageDirty([]);
            try {
                await updateProductQuantity({
                    cabinetId: productDetails.id,
                    quantity,
                });

                dispatch(invalidateProductAndCost());

                genericMessageHandler(
                    notify,
                    {message: 'Product quantity updated'},
                    'success'
                );

                setSaving(false);
            } catch (e) {
                genericMessageHandler(notify, {
                    message:
                        'Product quantity could not be updated. Please try again later.',
                });
                setSaving(false);
            }
        }
    };

    useEffect(() => {
        if (value != quantity) setQuantity(value);
    }, [value]);

    return {
        quantity,
        decreaseQuantity,
        increaseQuantity,
        updateQuantity,
        updateProduct,
        saving,
    };
};
